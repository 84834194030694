export class BatteryModel {
  constructor(state) {
    this.state = state;
    this.state.shouldCharge = this.state.shouldCharge || ((produced) => false);
    this.state.currentCharge = state.currentCharge === undefined ? 0 : state.currentCharge;
    this.state.isCharging = this.state.isCharging || false;
  }

  hasCharge = () => {
    return this.state.currentCharge > 0;
  }

  getCurrentCharge = () => {
    return this.state.currentCharge;
  }

  getUsableCapacity = () => {
    return this.state.capacityInkWh;
  }

  isFullyCharged = () => {
    return this.state.currentCharge >= this.getUsableCapacity();
  }

  discharge = (wattHour) => {
    const state = { ...this.state };
    state.currentCharge -= wattHour;
    return new BatteryModel(state);
  }

  charge = (wattHour) => {
    const state = { ...this.state };
    state.currentCharge += wattHour;
    return new BatteryModel(state);
  }
}