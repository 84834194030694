export const _carProfiles = [
  {
    name: "Works at the office the whole week",
    samples: [
      0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0
    ]
  },
  {
    name: "Works from home two days a week",
    samples: [
      0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0
    ]
  },
  {
    name: "Mostly works from home",
    samples: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0.5, 0, 0, 0.5, 0, 1, 1, 1, 1,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.5, 0, 0, 0.5, 0, 0, 0, 0, 0
    ]
  },
  {
    name: "Always home",
    samples: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
    ]
  }
].map(x => {
  x.hoursAwayPerYear = x.samples.reduce((sum, x) => sum + x, 0) * 365 / 7;
  return x;
});


export class CarModel {
  constructor(state) {
    this.state = state;
    this.state.maximalChargeRatio = state.maximalChargeRatio || 0.8;
    this.state.currentCharge = state.currentCharge === undefined ? (state.capacityInkWh * state.maximalChargeRatio) : state.currentCharge;
    this.state.profileIndex = state.profileIndex || 0;
    this.state.averageChargeRate = state.averageChargeRate || 7;
    this.state.isCharging = this.state.isCharging || false;
  }

  selectProfile = (index) => {
    const state = { ...this.state };
    state.profileIndex = index % _carProfiles.length;
    return new CarModel(state);
  }

  tick = (index) => {
    const state = { ...this.state };
    const hourIndex = Math.floor(index * this.state.hoursPerTimestep);
    const profile = _carProfiles[this.state.profileIndex];
    const chanceIsAway = profile.samples[hourIndex % profile.samples.length];
    const consumedPerHour = state.kmPerYear * state.kWhPerKm / profile.hoursAwayPerYear;
    const leftUntilFull = Math.max((state.maximalChargeRatio * state.capacityInkWh) - state.currentCharge, 0);
    const chargeRatio = state.currentCharge / state.capacityInkWh;

    state.isAtHome = Math.random() > chanceIsAway;
    if (state.isAtHome && (chargeRatio < 0.5)) {
      state.chargedThisTick = Math.min(state.averageChargeRate * state.hoursPerTimestep, leftUntilFull);
      state.dischargedThisTick = 0;
      state.currentCharge += state.chargedThisTick;
      state.isCharging = leftUntilFull > 0;
    } else {
      const dischargedThisTick = Math.min(state.isAtHome ? 0 : consumedPerHour * this.state.hoursPerTimestep, state.currentCharge);
      state.chargedThisTick = 0;
      state.dischargedThisTick = dischargedThisTick;
      state.currentCharge -= state.dischargedThisTick;
      state.isCharging = false;
    }
    // console.log({
    //   isCharging: state.isCharging,
    //   isAtHome: state.isAtHome,
    //   chargedThisTick: state.chargedThisTick,
    //   dischargedThisTick: state.dischargedThisTick,
    //   currentCharge: state.currentCharge
    // });
    return new CarModel(state);
  }

  isCharging = () => {
    return this.state.isCharging;
  }

  getChargedThisTick = () => {
    return this.state.chargedThisTick;
  }

  isAtHome = () => {
    return this.state.isAtHome;
  }

  hasCharge = () => {
    return this.state.currentCharge > 0;
  }

  getCurrentCharge = () => {
    return this.state.currentCharge;
  }

  getUsableCapacity = () => {
    return this.state.maximalChargeRatio * this.state.capacityInkWh;
  }

  isFullyCharged = () => {
    return this.state.currentCharge >= this.getUsableCapacity();
  }

  discharge = (kiloWattHour) => {
    const state = { ...this.state };
    state.currentCharge -= kiloWattHour;
    return new CarModel(state);
  }

  charge = (kiloWattHour) => {
    const state = { ...this.state };
    state.currentCharge += kiloWattHour;
    return new CarModel(state);
  }
}