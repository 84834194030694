import React from 'react';
import './infra.js';
import './App.css';
import '../node_modules/react-vis/dist/style.css';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries } from 'react-vis';
import { _panelRatios, _profiles } from './Profiles';
import { _determineCost, _energyPrices } from './Prices';
import { _carProfiles, CarModel } from './CarModel';
import { BatteryModel } from './BatteryModel';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      pvWattage: 325,
      panels: 24,
      inverterWattage: 5000,
      inverterEfficiency: 95,
      batteryMaximalCapacity: 0,
      batteryChargeRateWatt: 230 * 10, // 10 A charging off of mains
      carBatteryMaximalCapacity: 0,
      carWattHourPerKm: 160,
      kmPerYear: 15000,
      orientationRatio: 0.87,
      producedPerHour: [],
      producedPerDay: [],
      producedPerMonth: [],
      batteryConsumedPerHour: [],
      batteryConsumedPerDay: [],
      batteryConsumedPerMonth: [],
      consumedPerHour: [],
      consumedPerDay: [],
      consumedPerMonth: [],
      shortagePerQuarter: [],
      shortagePerHour: [],
      shortagePerDay: [],
      shortagePerMonth: [],
      excessPerHour: [],
      excessPerDay: [],
      excessPerMonth: [],
      batteryActualCapacityPerTimestep: [],
      batteryActualCapacityPerHour: [],
      batteryActualCapacityPerDay: [],
      shortagePerHourCost: [],
      shortagePerDayCost: [],
      shortagePerMonthCost: [],
    };
  }

  componentDidMount() {
    this.simulate();
  }

  simulate = (e) => {
    if (e) e.preventDefault();
    const pv_kiloWatt = this.state.pvWattage / 1e3;
    const panels = this.state.panels;
    const orientationRatio = this.state.orientationRatio;
    const inverterKiloWattage = this.state.inverterWattage / 1e3;
    const inverterEfficiency = this.state.inverterEfficiency / 100;

    const yearInHours = 365 * 24;
    const hoursPerTimestep = yearInHours / _panelRatios.length;
    const timestepsPerHour = Math.floor(_panelRatios.length / yearInHours);
    const timestepsPerDay = timestepsPerHour * 24;

    const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    const monthLookup = [];
    for (let i = 0; i < daysPerMonth.length; i++) {
      for (let j = 0; j < daysPerMonth[i]; j++) {
        monthLookup.push(i);
      }
    }

    const produced = [];
    const producedPerHour = [];
    const producedPerDay = [];
    const producedPerMonth = [];

    const batteryConsumedPerHour = [];
    const batteryConsumedPerDay = [];
    const batteryConsumedPerMonth = [];

    const consumedPerHour = [];
    const consumedPerDay = [];
    const consumedPerMonth = [];

    const shortagePerQuarter = [];
    const shortagePerHour = [];
    const shortagePerDay = [];
    const shortagePerMonth = [];

    const excessPerHour = [];
    const excessPerDay = [];
    const excessPerMonth = [];

    const batteryActualCapacityPerTimestep = [];

    let carModel = new CarModel({
      capacityInkWh: this.state.carBatteryMaximalCapacity / 1e3,
      kWhPerKm: this.state.carWattHourPerKm / 1e3,
      kmPerYear: this.state.kmPerYear,
      hoursPerTimestep: hoursPerTimestep,
      profileIndex: this.state.carProfileIndex
    });

    let batteryModel = new BatteryModel({
      capacityInkWh: this.state.batteryMaximalCapacity / 1e3,
      hoursPerTimestep: hoursPerTimestep,
    });

    for (let index = 0; index < _panelRatios.length; index++) {
      const hourIndex = Math.floor(index / timestepsPerHour);
      const dayIndex = Math.floor(hourIndex / 24);
      const monthIndex = monthLookup[dayIndex];

      // Production
      const ratio = _panelRatios[index] * orientationRatio;
      const producedThisTimestepUnderIdealCircumstances = pv_kiloWatt * panels * hoursPerTimestep;
      const producedThisTimestep = Math.min(ratio * producedThisTimestepUnderIdealCircumstances * inverterEfficiency, inverterKiloWattage * inverterEfficiency * hoursPerTimestep);

      produced.push(producedThisTimestep);
      producedPerHour[hourIndex] = (producedPerHour[hourIndex] || 0) + producedThisTimestep;
      producedPerDay[dayIndex] = (producedPerDay[dayIndex] || 0) + producedThisTimestep;
      producedPerMonth[monthIndex] = (producedPerMonth[monthIndex] || 0) + producedThisTimestep;

      carModel = carModel.tick(index, hoursPerTimestep);

      // Consumption
      const carChargedThisTick = carModel.getChargedThisTick();

      let batteryChargedThisTick = 0;
      const cheapNetPrices = _energyPrices[hourIndex % _energyPrices.length] < .20;
      const hasSolarPanels = this.state.panels > 0;
      const isInChargingTimeslot = cheapNetPrices
        && (!hasSolarPanels || (dayIndex < 45 || dayIndex > 305));
      const areSolarPanelsProducing = producedThisTimestep > 0;
      const shouldChargeHomeBatteryOffTheNet = !batteryModel.isFullyCharged()
        && !areSolarPanelsProducing
        && isInChargingTimeslot;
      if (shouldChargeHomeBatteryOffTheNet) {
        const batteryChargeRatekWh = this.state.batteryChargeRateWatt / (1e3 * timestepsPerHour);
        batteryChargedThisTick = Math.min(batteryModel.getUsableCapacity() - batteryModel.getCurrentCharge(), batteryChargeRatekWh);
        batteryModel = batteryModel.charge(batteryChargedThisTick);
      }

      const consumedThisTimestep = (_profiles
        .reduce((consumed, profile) => consumed + ((profile.count || 1) * profile.samples[hourIndex % profile.samples.length]), 0)
        * hoursPerTimestep / 1e3)
        + carChargedThisTick
        + batteryChargedThisTick
        ;

      batteryConsumedPerHour[hourIndex] = (batteryConsumedPerHour[hourIndex] || 0) + batteryChargedThisTick;
      batteryConsumedPerDay[dayIndex] = (batteryConsumedPerDay[dayIndex] || 0) + batteryChargedThisTick;
      batteryConsumedPerMonth[monthIndex] = (batteryConsumedPerMonth[monthIndex] || 0) + batteryChargedThisTick;

      consumedPerHour[hourIndex] = (consumedPerHour[hourIndex] || 0) + consumedThisTimestep;
      consumedPerDay[dayIndex] = (consumedPerDay[dayIndex] || 0) + consumedThisTimestep;
      consumedPerMonth[monthIndex] = (consumedPerMonth[monthIndex] || 0) + consumedThisTimestep;

      let shortageThisTimestep = Math.max(consumedThisTimestep - producedThisTimestep, 0);
      if (shortageThisTimestep && batteryModel.hasCharge() && (batteryChargedThisTick <= 0)) {
        const shortageMitigation = Math.min(batteryModel.getCurrentCharge(), shortageThisTimestep);
        shortageThisTimestep -= shortageMitigation;
        batteryModel = batteryModel.discharge(shortageMitigation);
      }

      const isCarAtHome = carModel.isAtHome(hourIndex);
      if (shortageThisTimestep && isCarAtHome && carModel.hasCharge() && !carModel.isCharging()) {
        const shortageMitigation = Math.min(carModel.getCurrentCharge(), shortageThisTimestep);
        shortageThisTimestep -= shortageMitigation;
        carModel = carModel.discharge(shortageMitigation);
      }

      shortagePerQuarter[index] = shortageThisTimestep;
      shortagePerHour[hourIndex] = (shortagePerHour[hourIndex] || 0) + shortageThisTimestep;
      shortagePerDay[dayIndex] = (shortagePerDay[dayIndex] || 0) + shortageThisTimestep;
      shortagePerMonth[monthIndex] = (shortagePerMonth[monthIndex] || 0) + shortageThisTimestep;

      let excessThisTimestep = Math.max(producedThisTimestep - consumedThisTimestep, 0);
      if (excessThisTimestep && !batteryModel.isFullyCharged()) {
        const excessMitigation = Math.min(batteryModel.getUsableCapacity() - batteryModel.getCurrentCharge(), excessThisTimestep);
        excessThisTimestep -= excessMitigation;
        batteryModel = batteryModel.charge(excessMitigation);
      }

      if (excessThisTimestep && isCarAtHome && !carModel.isFullyCharged()) {
        const excessMitigation = Math.min(carModel.getUsableCapacity() - carModel.getCurrentCharge(), excessThisTimestep);
        excessThisTimestep -= excessMitigation;
        carModel = carModel.charge(excessMitigation);
      }

      excessPerHour[hourIndex] = (excessPerHour[hourIndex] || 0) + excessThisTimestep;
      excessPerDay[dayIndex] = (excessPerDay[dayIndex] || 0) + excessThisTimestep;
      excessPerMonth[monthIndex] = (excessPerMonth[monthIndex] || 0) + excessThisTimestep;

      batteryActualCapacityPerTimestep.push(batteryModel.getCurrentCharge());
    }

    const batteryActualCapacityPerHour = batteryActualCapacityPerTimestep.cluster(() => timestepsPerHour, xs => xs.sum() / timestepsPerHour);
    const batteryActualCapacityPerDay = batteryActualCapacityPerTimestep.cluster(() => timestepsPerDay, xs => xs.sum() / timestepsPerDay);

    const shortagePerHourCost = shortagePerHour.map((kWh, hourIndex) => _energyPrices[hourIndex % _energyPrices.length] * kWh);
    const shortagePerDayCost = shortagePerHourCost.cluster(() => 24, xs => xs.sum());
    const shortagePerMonthCost = shortagePerDayCost.cluster((_, dayIndex) => daysPerMonth[dayIndex], xs => xs.sum());

    this.setState({
      produced,
      producedPerHour,
      producedPerDay,
      producedPerMonth,
      batteryConsumedPerHour,
      batteryConsumedPerDay,
      batteryConsumedPerMonth,
      consumedPerHour,
      consumedPerDay,
      consumedPerMonth,
      shortagePerQuarter,
      shortagePerHour,
      shortagePerDay,
      shortagePerMonth,
      excessPerHour,
      excessPerDay,
      excessPerMonth,
      batteryActualCapacityPerTimestep,
      batteryActualCapacityPerHour,
      batteryActualCapacityPerDay,
      shortagePerHourCost,
      shortagePerDayCost,
      shortagePerMonthCost,
    });
  }

  handleChange = (event, field) => {
    const state = {};
    state[field] = event.target.value;
    this.setState(state);
  }

  render() {
    const start = 0;
    const end = this.state.producedPerDay.length;
    const produced = this.state.producedPerDay.slice(start, end);
    const consumed = this.state.consumedPerDay.slice(start, end);
    const batteryConsumed = this.state.batteryConsumedPerDay.slice(start, end);
    const shortage = this.state.shortagePerDay.slice(start, end);
    const shortageCost = this.state.shortagePerDayCost.slice(start, end);
    const excess = this.state.excessPerDay.slice(start, end);
    const battery = this.state.batteryActualCapacityPerDay.slice(start, end);

    const producedTotal = produced.sum();
    const consumedTotal = consumed.sum();
    const batteryConsumedTotal = batteryConsumed.sum();
    const shortageTotal = shortage.sum();
    const excessTotal = excess.sum();
    const shortageTotalCost = shortageCost.sum();

    const optimalEnergyPrice = _energyPrices.min();
    const optimalCost = ((consumedTotal - batteryConsumedTotal) * optimalEnergyPrice);

    const costResult = _determineCost(this.state.shortagePerQuarter);
    const effectiveShortageTotalCost = costResult.cost;

    return (
      <div className="App">
        <header className="App-header">
          <div>
            Opgewekt PV (green): {producedTotal.toFixed(1)} kWh
            Verbruik huishouden (red): {consumedTotal.toFixed(1)} kWh
          </div>
          <div>
            Teveel opgewekt (lightgreen): {excessTotal.toFixed(1)} kWh
            Van het net (orange): {shortageTotal.toFixed(1)} kWh
          </div>
          <div>
            Kost van het net: {effectiveShortageTotalCost.toFixed(1)} EUR
            Gemiddelde Maandelijkse Piek: {costResult.averageMonthlyPeak.toFixed(1)} kW
          </div>
          <XYPlot
            animation
            width={1280}
            height={800}>
            <HorizontalGridLines />
            <LineSeries data={produced.map((y, x) => ({ x, y }))} color="green" />
            <LineSeries data={consumed.map((y, x) => ({ x, y }))} color="red" />
            <LineSeries data={shortage.map((y, x) => ({ x, y }))} color="orange" />
            <LineSeries data={excess.map((y, x) => ({ x, y }))} color="lightgreen" />
            <LineSeries data={battery.map((y, x) => ({ x, y }))} color="lightblue" />
            <XAxis />
            <YAxis />
          </XYPlot>
        </header>
        <form>
          <label htmlFor="pvWattage">PV Power</label>
          <div>
            <input id="pvWattage" type="number" value={this.state.pvWattage} onChange={e => this.handleChange(e, "pvWattage")} /><span> W</span>
          </div>

          <label htmlFor="panels">Panels</label>
          <input id="panels" type="number" value={this.state.panels} onChange={e => this.handleChange(e, "panels")} />

          <label htmlFor="inverterWattage">Inverter Power</label>
          <div>
            <input id="inverterWattage" type="number" value={this.state.inverterWattage} onChange={e => this.handleChange(e, "inverterWattage")} /><span> W</span>
          </div>

          <label htmlFor="inverterEfficiency">Inverter Efficiency</label>
          <div>
            <input id="inverterEfficiency" type="number" value={this.state.inverterEfficiency} onChange={e => this.handleChange(e, "inverterEfficiency")} /><span> %</span>
          </div>


          <label htmlFor="batteryMaximalCapacity">Home battery capacity</label>
          <div>
            <input id="batteryMaximalCapacity" type="number" value={this.state.batteryMaximalCapacity} onChange={e => this.handleChange(e, "batteryMaximalCapacity")} /><span> Wh</span>
          </div>

          <label htmlFor="carBatteryMaximalCapacity">Car battery capacity</label>
          <div>
            <input id="carBatteryMaximalCapacity" type="number" value={this.state.carBatteryMaximalCapacity} onChange={e => this.handleChange(e, "carBatteryMaximalCapacity")} /><span> Wh</span>
          </div>

          <label htmlFor="carWattHourPerKm">Car Wh/km</label>
          <div>
            <input id="carWattHourPerKm" type="number" value={this.state.carWattHourPerKm} onChange={e => this.handleChange(e, "carWattHourPerKm")} /><span> Wh/km</span>
          </div>

          <label htmlFor="kmPerYear">Car kilometers per year</label>
          <div>
            <input id="kmPerYear" type="number" value={this.state.kmPerYear} onChange={e => this.handleChange(e, "kmPerYear")} /><span> km/y</span>
          </div>

          <label htmlFor="carProfile">Car profile</label>
          <select value={this.state.carProfileIndex} onChange={e => this.handleChange(e, "carProfileIndex")} >
            {_carProfiles.map((profile, index) => (<option key={`car-profile-${index}`} value={index}>{profile.name}</option>))}
          </select>

          <label htmlFor="orientationRatio">Orientation ratio (0.90 for South non ideal angle)</label>
          <input id="orientationRatio" type="number" value={this.state.orientationRatio} onChange={e => this.handleChange(e, "orientationRatio")} />

          <button onClick={this.simulate}>Simulate</button>
        </form>
      </div>
    );
  }
}

export default App;
