const _lP = .21;
const _hP = .21;

const daysPerMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
const monthLookup = [];
for (let i = 0; i < daysPerMonth.length; i++) {
  for (let j = 0; j < daysPerMonth[i]; j++) {
    monthLookup.push(i);
  }
}

export const _energyPrices =
  [
    _lP, _lP, _lP, _lP, _lP, _lP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _lP, _lP,
    _lP, _lP, _lP, _lP, _lP, _lP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _lP, _lP,
    _lP, _lP, _lP, _lP, _lP, _lP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _lP, _lP,
    _lP, _lP, _lP, _lP, _lP, _lP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _lP, _lP,
    _lP, _lP, _lP, _lP, _lP, _lP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _hP, _lP, _lP,
    _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP,
    _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP, _lP,
  ];

export const _determineCost = (shortagePerQuarter) => {
  const fixedPart = shortagePerQuarter.sum() * _lP;
  const quarterPeaks = shortagePerQuarter.map(x => x * 4);
  const monthlyPeaks = [];
  for (let i = 0; i < quarterPeaks.length; i++) {
    const hourIndex = Math.floor(i / 4);
    const dayIndex = Math.floor(hourIndex / 24);
    const monthIndex = monthLookup[dayIndex];
    monthlyPeaks[monthIndex] = Math.max(quarterPeaks[i], monthlyPeaks[monthIndex] || 0);
  }
  const averageMonthlyPeak = Math.max(2.5, monthlyPeaks.sum() / monthlyPeaks.length);
  console.log(monthlyPeaks);
  const capacityPart = averageMonthlyPeak * 38.6;
  return { cost: fixedPart + capacityPart, averageMonthlyPeak };
};