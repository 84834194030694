window.Array.prototype.min = function () {
  return this.reduce((p, c) => Math.min(p, c), 1e9999999);
};
window.Array.prototype.max = function () {
  return this.reduce((p, c) => Math.max(p, c), -1e9999999);
};
window.Array.prototype.sum = function () {
  return this.reduce((p, c) => p + c, 0);
};
window.Array.prototype.cluster = function (getStep, reduce) {
  const items = this;
  const result = [];
  let step = getStep(0, 0);
  for (let index = 0, i = 0; index < items.length; index += step, i++) {
    result.push(reduce(items.slice(index, index + step)));
    step = getStep(index, i);
  }
  return result;
};